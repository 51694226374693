<template>
  <Notification v-show="elementVisible" />

  <div class="mb-6">
    <label for="email" class="block mb-2 text-sm font-medium text-white">E-Mail</label>
    <input type="email" id="email" v-model="email"
           class="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
  </div>
  <div class="mb-6">
    <label for="zip" class="block mb-2 text-sm font-medium text-white">ZIP Code</label>
    <input type="text" id="zip" v-model="zip"
           class="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
  </div>
  <button v-on:click="save"
          class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">
    Update
  </button>
  <br>
  <button v-on:click="logout"
          class="mt-5 text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-red-600 hover:bg-red-700 focus:ring-red-800">
    Logout
  </button>
</template>

<script>
import axios from "axios";
import Notification from "@/components/Notification.vue";

export default {
  name: "Settings",
  components: { Notification },
  data() {
    return {
      zip: "",
      email: "",
      elementVisible: false
    };
  },
  methods: {
    save() {
      const self = this;
      axios
        .post(
          "https://vishalini.vish.family/api/v1/update",
          {
            email: self.email,
            zip: self.zip
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("app-token")
            }
          }
        )
        .then(function(response) {
          self.elementVisible = true;
          self.getUser();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getUser() {
      axios
        .get("https://vishalini.vish.family/api/v1/user", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("app-token")
          }
        })
        .then((response) => {
          this.email = response.data.user.email;
          this.zip = response.data.user.zip;
        });
    },

    logout() {
      localStorage.clear();
      this.$router.push({ path: "/login" });
    }
  },
  mounted() {
    this.getUser();
  },
};
</script>
<style>
.custom {
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
}
</style>
