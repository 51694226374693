<template>
  <Notification v-show="elementVisible" />
  <label for="underline_select" class="sr-only">Underline select</label>
  <select v-model="selectedDay"
          class="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none text-gray-400 border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
    <option v-for="date in dates">{{ date }}</option>
  </select>
  <ul class="divide-y divide-gray-700">
    <li class="py-3 sm:pb-4" v-for="dish in dishes">
      <div class="flex items-center space-x-4">
        <div class="flex-shrink-0">
          <img class="w-8 h-8 rounded-full" src="https://picsum.photos/200/200"
               alt="Neil image">
        </div>
        <div class="flex-1 min-w-0">
          <p class="text-sm font-medium truncate text-white">
            {{ dish.name }}
          </p>
          <p class="text-sm truncate text-gray-400">
            {{ dish.dishProducts }}
          </p>
        </div>
        <div class="inline-flex items-center text-base font-semibold text-white">
          <button @click="addDish(dish.id)" type="button"
                  class="text-white focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 4V20M4 12H20" stroke="#fff" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import axios from "axios";
import Notification from "../components/Notification.vue";

export default {
  props: {},
  components: {
    Notification
  },
  data() {
    return {
      dishes: [],
      dates: [],
      selectedDay: "",
      elementVisible: false
    };
  },
  mounted() {
    let token = localStorage.getItem("app-token");
    if (!token) {
      this.$router.push({ path: "/login" });
    } else {
      axios
        .get("https://vishalini.vish.family/api/v1/dishes")
        .then((response) => {
          this.dishes = response.data.dishes;
        });
      axios
        .get("https://vishalini.vish.family/api/v1/dates")
        .then((response) => {
          this.dates = response.data;
          this.selectedDay = response.data[0];
        });
    }
  },
  methods: {
    addDish(dishId) {
      const self = this;
      axios
        .post("https://vishalini.vish.family/api/v1/weeklyDishes", {
          date: this.selectedDay,
          dish_id: dishId
        })
        .then(function(response) {
          console.log(response);
          self.elementVisible = true;
          setTimeout(() => (self.elementVisible = false), 1000);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
