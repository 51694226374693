<template>
  <ol class="relative border-l border-gray-700">
    <li class="mb-10 ml-4" v-for="(dishes, index) in days">
      <div class="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-gray-900 bg-gray-700"></div>
      <time class="mb-1 text-sm font-normal leading-none text-gray-500">{{ index }}</time>

      <div v-for="dish in dishes">

        <h3 class="text-lg font-semibold text-white mb-2">{{ dish.name }}
          <button @click="destroy(dish.id)" type="button"
                  class="ml-2 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white rounded-lg focus:ring-4 focus:outline-none bg-red-600 hover:bg-red-700 focus:ring-red-800">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
              <path
                d="M4 7H20M10 10V18M14 10V18M10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3ZM6 7H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H7C6.73478 21 6.48043 20.8946 6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20V7Z"
                stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <router-link :to="`/dishProducts/${dish.originalId}`" type="button"
                       class="ml-2 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white rounded-lg focus:ring-4 focus:outline-none bg-green-600 hover:bg-green-700 focus:ring-green-800">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
              <path
                d="M8 5H12M10 7V3M6 15C4.89543 15 4 15.8954 4 17C4 18.1046 4.89543 19 6 19C7.10457 19 8 18.1046 8 17C8 15.8954 7.10457 15 6 15ZM6 15H14M6 15L5 11M14 15C12.8954 15 12 15.8954 12 17C12 18.1046 12.8954 19 14 19C15.1046 19 16 18.1046 16 17C16 15.8954 15.1046 15 14 15ZM14.938 4H17L15 11H5M5 11L3 4M3 4H5M3 4L2.208 1H1"
                stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </router-link>
        </h3>
        <p class="mb-4 text-base font-normal text-gray-400">
          {{ dish.dishProducts }}
        </p>
      </div>
    </li>
  </ol>
</template>

<script>
import axios from "axios";

export default {
  name: "WeekPlan",
  props: {
    msg: String
  },
  data() {
    return {
      days: []
    };
  },
  mounted() {
    let token = localStorage.getItem("app-token");
    if (!token) {
      this.$router.push({ path: "/login" });
    } else {
      this.getDishes();
    }
  },
  methods: {
    destroy(id) {
      axios
        .delete(`https://vishalini.vish.family/api/v1/weeklyDishes/${id}`)
        .then((response) => {
          this.days = response.data.dishes;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDishes() {
      axios
        .get("https://vishalini.vish.family/api/v1/weeklyDishes")
        .then((response) => {
          this.days = response.data.dishes;
        });
    }
  }
};
</script>
