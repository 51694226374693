import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import Vuex from 'vuex';
import axios from "axios";
import VueAxios from "vue-axios";
import "./registerServiceWorker";
import "./assets/tailwind.css";
import store from './store'; //

import App from "./App.vue";
import Login from "./views/Login.vue";
import Home from "./views/Home.vue";
import Profile from "./views/Profile.vue";
import Dishes from "./views/Dishes.vue";
import DishProducts from "./views/DishProducts.vue";
import PriceSearch from "./views/PriceSearch.vue";

const routes = [
  { path: "/login", component: Login },
  { path: "/", component: Home },
  { path: "/profile", component: Profile },
  { path: "/dishes", component: Dishes },
  { path: "/dishProducts/:dishId", component: DishProducts },
  { path: "/PriceSearch", component: PriceSearch },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router, VueAxios, axios).use(store).mount("#app");
