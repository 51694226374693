<template>
  <div class="container mx-auto p-5 customer-container">
    <RouterView />
  </div>
  <Navbar />
</template>

<script>
// import WeekPlan from "./components/WeekPlan.vue";
import Navbar from "./components/Navbar.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    // WeekPlan,
    Navbar
  },
  async created() {

  },
  async mounted() {
    let token = localStorage.getItem("app-token");
    if (!token) {
      this.$router.push({ path: "/login" });
    } else {
      const response = await axios.get(
        "https://vishalini.vish.family/api/v1/user",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("app-token")
          },
        }
      );
      this.$store.dispatch("userEmail", response.data.user.email);
      this.$store.dispatch("userZip", response.data.user.zip);
    }
  },
};
</script>

<style>
#app {
  .test {
    color: black;
  }
}

.customer-container {
  height: calc(100vh - 82px);
  overflow-y: scroll;
}
</style>
