<template>
  <Notification v-show="elementVisible" />
  <h3 class="mb-4 text-3xl font-extrabold text-white md:text-5xl lg:text-6xl">
    <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Bring!</span>
  </h3>

  <ul class="divide-y divide-gray-700 mb-4">
    <li class="py-3 sm:pb-4" v-for="dishProduct in dishProducts">
      <div class="flex items-center space-x-4">
        <div class="flex-shrink-0">
          <img class="w-8 h-8 rounded-full" src="https://picsum.photos/200/200"
               alt="Neil image">
        </div>
        <div class="flex-1 min-w-0">
          <p class="text-sm font-medium truncate text-white">
            {{ dishProduct.name }}
          </p>
        </div>
        <div class="inline-flex items-center text-base font-semibold text-white">
          <button @click="addItem(dishProduct.name)" type="button"
                  class="text-white focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 bg-green-600 hover:bg-green-700 focus:ring-green-800">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 4V20M4 12H20" stroke="#fff" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </li>
  </ul>

  <button @click="addAll()" class="w-full inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-green-800">
  <span class="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-md group-hover:bg-opacity-0">
      Bring! All
  </span>
  </button>

  <div class="pb-28"></div>

</template>

<script>
import axios from "axios";
import Notification from "@/components/Notification.vue";

export default {
  components: { Notification },
  data() {
    return {
      dishProducts: [],
      dishName: "",
      elementVisible: false,
    };
  },
  mounted() {
    let token = localStorage.getItem("app-token");
    if (!token) {
      this.$router.push({ path: "/login" });
    } else {
      this.getDishes();
    }
  },
  methods: {
    addItem(name) {
      const self = this;
      axios
        .post("https://vishalini.vish.family/api/v1/bring", {
          item: name,
          tag: this.dishName,
        })
        .then(function (response) {
          console.log(response);
          self.elementVisible = true;
          setTimeout(() => (self.elementVisible = false), 1000);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addAll() {
      const self = this;
      const dishId = this.$route.params.dishId;
      axios
        .post("https://vishalini.vish.family/api/v1/bring", {
          dishId: dishId,
        })
        .then(function (response) {
          console.log(response);
          self.elementVisible = true;
          setTimeout(() => (self.elementVisible = false), 1000);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getDishes() {
      const dishId = this.$route.params.dishId;
      axios
        .get("https://vishalini.vish.family/api/v1/dishProducts/" + dishId)
        .then((response) => {
          this.dishProducts = response.data.dishProducts;
          this.dishName = response.data.dishName;
        });
    },
  },
};
</script>
