<template>
  <div class="flex w-full custom">
    <div class="w-full p-5">
      <div class="mb-6">
        <label for="email" class="block mb-2 text-sm font-medium text-white"
        >Your email</label
        >
        <input type="email" id="email" v-model="email"
               class=" border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
      </div>
      <div class="mb-6">
        <label for="password" class="block mb-2 text-sm font-medium text-white">Your password</label>
        <input type="password" id="password" v-model="password"
               class="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
      </div>

      <button type="submit" v-on:click="login"
              class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">
        Login
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Profile",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      const response = await axios.post(
        "https://vishalini.vish.family/api/v1/login",
        {
          email: this.email,
          password: this.password,
        }
      );

      console.log(response.data["user"]);
      localStorage.setItem("app-token", response.data["token"]);
      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    let token = localStorage.getItem("app-token");
    if (token) {
      this.$router.push({ path: "/" });
    }
  }
};
</script>
<style>
.custom {
  height: calc(100vh - 120px);
  justify-content: center;
  align-items: center;
}
</style>
