<template>
  <Notification v-show="elementVisible" />
  <div>
    <div class="mb-1">
      <input type="email" id="email" v-model="itemName" @input="search" placeholder="Search..."
             class="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
    </div>

    <ul class="divide-y divide-gray-700">
      <li class="py-3 sm:pb-4" v-for="result in results">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0">
            <img class="w-8 h-8 rounded-full" src="https://picsum.photos/200/200"
                 alt="Neil image">
          </div>
          <div class="flex-1 min-w-0">
            <p class="text-sm font-medium truncate text-white">
              {{ result.advertisers[0].name }} — {{ result.price }} EUR
            </p>
            <p class="text-sm truncate text-gray-400">
              {{ result.description }}
            </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-white">
            <button @click="addItem(result.advertisers[0].name)" type="button"
                    class="text-white focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 4V20M4 12H20" stroke="#fff" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import Notification from "@/components/Notification.vue";
import { mapGetters } from "vuex";

export default {
  components: { Notification },
  name: "PriceSearch",
  data() {
    return {
      results: null,
      itemName: "",
      elementVisible: false
    };
  },
  methods: {
    search() {
      axios
        .get(
          "https://api.marktguru.de/api/v1/offers/search?as=web&limit=24&offset=0&q=" +
          this.itemName +
          "&zipCode=" +
          this.userZip,
          {
            headers: {
              "x-clientkey": "WU/RH+PMGDi+gkZer3WbMelt6zcYHSTytNB7VpTia90=",
              "x-apikey": "8Kk+pmbf7TgJ9nVj2cXeA7P5zBGv8iuutVVMRfOfvNE="
            }
          }
        )
        .then((response) => {
          this.results = response.data.results;
        });
    },
    addItem(discounterName) {
      const self = this;
      axios
        .post("https://vishalini.vish.family/api/v1/bring", {
          item: this.itemName,
          tag: discounterName
        })
        .then(function() {
          self.elementVisible = true;
          setTimeout(() => (self.elementVisible = false), 1000);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  computed: {
    ...mapGetters(["userZip"])
  }
};
</script>
<style>
.custom {
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
}
</style>
